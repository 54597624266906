body {
    color: $success;
}
h2 {
    color: $info;
}
.bg-color-fourth {
    background-color: $color-fourth;
}
.bg-color-fifth {
    background-color: $color-fifth;
}
h1 {
    font-size: 56px;
    line-height: 72px;
    @include pad {
        font-size: 44px;
        line-height: 56px;
    }
    @include mobile {
        font-size: 32px;
        line-height: 40px;
    }
}
h2 {
    font-size: 48px;
    line-height: 62px;
    @include pad {
        font-size: 40px;
        line-height: 52px;
    }
    @include mobile {
        font-size: 28px;
        line-height: 36px;
    }
}
h3 {
    font-size: 40px;
    line-height: 52px;
    @include pad {
        font-size: 32px;
        line-height: 40px;
    }
    @include mobile {
        font-size: 24px;
        line-height: 32px;
    }
}
.h3 {
    font-size: 40px;
    line-height: 52px;
    @include pad {
        font-size: 32px;
        line-height: 40px;
    }
    @include mobile {
        font-size: 24px;
        line-height: 32px;
    }
}
h4 {
    font-size: 28px;
    line-height: 36px;
    @include pad {
        font-size: 24px;
        line-height: 32px;
    }
}
h5 {
    font-size: 24px;
    line-height: 32px;
    @include pad {
        font-size: 20px;
        line-height: 28px;
    }
    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}
h6 {
    font-size: 20px;
    line-height: 28px;
    @include pad {
        font-size: 18px;
        line-height: 24px;
    }
    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}
.h6 {
    font-size: 20px;
    line-height: 28px;
    @include pad {
        font-size: 18px;
        line-height: 24px;
    }
    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}
header {
    position: sticky;
    top: 0;
    z-index: 9;
}
footer {
    z-index: 1;
    // 漢堡選單無法覆蓋在footer上，所以改父層的z-index，將header的z-index設定大於footer
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}
.w-45 {
    width: 45%;
}
.w-60 {
    width: 60%;
}
.w-md-45 {
    @include pad {
        width: 45%;
    }
}
.w-sm-40 {
    @include mobile {
        width: 40%;
    }
}
.w-sm-75 {
    @include mobile {
        width: 75%;
    }
}
.w-md-WH76 {
    width: 76px;
    height: 76px;
}
.w-sm-WH40 {
    @include mobile {
        width: 40px;
        height: 40px;
    }
}
.bold {
    font-weight: 700;
}
.gap-28 {
    gap: 28px 0px;
}
.border-md-top {
    @include pad {
        border-top: 1px solid #fff;
    }
}
.border-md-bottom {
    @include pad {
        border-bottom: 1px solid #fff;
    }
}
.border-sm-bottom {
    @include mobile {
        border-bottom: 1px solid #fff;
    }
}
