.page-contact {
    .map {
        width: 80%;
        margin: 0 auto;
        @include pad {
            width: 100%;
            margin: 0 auto;
        }
    }
    .map-iframe {
        width: 100%;
        margin: 0 auto;
        height: 500px;
        @include pad {
            height: 450px;
        }
        @include mobile {
            height: 350px;
        }
    }
    @media screen and (max-width: $mobile-width) {
        .list-contact :nth-child(1) {
          order: 1;
        }
        .list-contact :nth-child(2) {
          order: 2;
        }
        .list-contact :nth-child(3) {
          order: 4;
        }
        .list-contact :nth-child(4) {
          order: 3;
        }
    }
}
