.member {
    width: 440px;
    margin: 0 auto;
    font-size: 1.2rem;
    box-shadow: 0px 2px 6px #00000029;
    border: 1px solid #DEE2E6;
    @include pad {
        max-width: 380px;
        margin: 0 auto;
    }
    @include mobile {
        max-width: 320px;
        margin: 0 auto;
    }
}
