// 以bootstrap5的xxl和iPad以下(767px)為斷點
$desktop-width: 1200px;
$pad-width: 992px;
$mobile-width: 767px;
// 容器
$container-lg: 1140px;
$container-md: 720px;
$container-sm: 540px;
// 顏色
$color-third: #746345;
$color-fourth: #ECEDED;
$color-fifth: #676665;
// header
// $nav-menu-text-primary: #000;
// $nav-menu-text-primary--hover: grey;
$nav-menu-child-bg-primary: #676665;
$ham-icon-bar-primary: gray;
$ham-icon-bg-primary: #fff;
$ham-icon-child-bg-primary: #F5F5F5;
$ham-menu-border-primary: #fff;
$ham-menu-bg-primary: #fff;

// footer
$footer-icon-primary: #ECEDED;
$footer-icon-primary--hover: gray;
// pages
$pages-link-primary: #000;
$pages-link-primary--hover: gray;
$section-bg-primary: #dfe6ed;
$section-bg-secondary: #fbe192;
