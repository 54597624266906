.InGo-solution {
    .banner-simple{
        background-image: url("../../assets/images/products/simple.png");
        width: 80px;
        height: 80px;
        @include banner;
    }
    .banner-integrate{
        background-image: url("../../assets/images/products/integrate.png");
        width: 80px;
        height: 80px;
        @include banner;
    }
    .banner-analysis{
        background-image: url("../../assets/images/products/analysis.png");
        width: 80px;
        height: 80px;
        @include banner;
    }
    .arc {
        position: relative;
        width: 100%;
        background-color: #fff;
    }
    .arc:after {
      position: absolute;
      left: 0;
      right: 0;
    //   bottom: -100px;
      z-index: 2;
      content: ' ';
      height: 120px;
      width: 100%;
      border-radius: 0 0 50% 50%;
      background-color: #fff;
      @include mobile {
        height: 90px;
        bottom: -60px;
        border-radius: 0 0 50% 50%;
        
      }
    }
    .half-ellipse {
        border-radius: 10% 10% 40% 10%;
        padding-top: 20px;
        padding-bottom: 100px;
    }
    .card:hover {
        box-shadow: 0 0 30px #00000040;
        transform: translateY(-15px);
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }

    
}
